﻿/*
Custom script for validating CFI Expiration Date
*/
(function ($) {
    $.validator.addMethod("cfiexpiredatecheck", function (value, element, params) {
        var expYear = value;
        var expMonth = $('#SelectedExpireDateMonth').val();
        var crSelected = $('input[name=' + params.coursereason + ']:checked').val();
        var fMode = $('#' + params.formmode).val();


        if (fMode === 'EFIRC' && crSelected) {
            if (params.requiredreasons.indexOf(crSelected) !== -1) {
                if (!isNaN(expYear) && !isNaN(expMonth)) {
                    var expDate = new Date(expYear * 1, (expMonth * 1) + 3, 0);
                    expDate.setDate(expDate.getDate() - 7);

                    var now = new Date();
                    now.setHours(0, 0, 0, 0);
                    if (expDate <= now) {
                        return false;
                    }
                } else {
                    return false;
                }
            } 
        }


        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("cfiexpiredatecheck", ["coursereason", "requiredreasons", "expiremonth", "formmode"],
        function (options) {
            options.rules['cfiexpiredatecheck'] = {
                coursereason: options.params.coursereason,
                requiredreasons: options.params.requiredreasons,
                expiremonth: options.params.expiremonth,
                formmode: options.params.formmode,
            };
            options.messages['cfiexpiredatecheck'] = options.message;
        });
}(jQuery));